import { mapGetters } from 'vuex';

export default {
	data () {
		return {
			editAdvertisementModal: {
				active: false,
				advertisement: null,
				imagePreview: ''
			},
			advertisement_types: [
				{id: 0, name: 'Diverse'},
				{id: 1, name: 'Nirov'}
			],
			newAdvertisement: {
				title: '',
				description: '',
				publication_date: '',
				expiration_date: '',
				advertisement_type: [],
				url_source: '',
				url_article: '',
        image: ''
			},
			imagePreview: '',
			baseUrl: process.env.VUE_APP_BASE_URL,
			errorMessages: []
		}
	},
	computed: {
		...mapGetters({
			advertisements: 'advertisement/advertisements',
			isLoadingResources: 'isLoadingResources'
		})
	},
	created () {
		this.$store.dispatch('advertisement/fetchAdvertisements').then(() => {
			this.isLoading = false;
		})
	},
	methods: {
		blobToBase64(blob) {
			return new Promise((resolve, _) => {
				const reader = new FileReader();
				reader.onloadend = () => resolve(reader.result);
				reader.readAsDataURL(blob);
			});
		},
		createAdvertisement () {
			let formData = new FormData();

			for ( let key in this.newAdvertisement ) {
				formData.append(`advertisement[${key}]`, this.newAdvertisement[key]);
			}
			this.$store.dispatch('advertisement/createAdvertisement', formData).then(() => {
				this.errorMessages = []
				this.imagePreview = '';
				this.newAdvertisement = {
					title: '',
					description: '',
					publication_date: '',
					expiration_date: '',
					advertisement_type: [],
					url_source: '',
					url_article: '',
					image: ''
				}
			}).catch((errors) => {
				this.errorMessages = errors;
			});
		},
		deleteAdvertisement (id) {
			this.$store.dispatch('advertisement/deleteAdvertisement', id).then(() => {
				this.$notify({
					title: 'Advertentie verwijderd',
					message: 'Advertentie is succesvol verwijderd!',
					type: 'success',
					position: 'bottom-right'
				})
			});
		},
		editAdvertisement (advertisement) {
			this.editAdvertisementModal = {
				active: true,
				advertisement: {...advertisement},
				imagePreview: `${process.env.VUE_APP_BASE_URL}	${advertisement.image.url}`
			}
			this.editAdvertisementModal.advertisement.advertisement_type = advertisement.advertisement_type.id
			this.editAdvertisementModal.advertisement.image = null
		},
		updateAdvertisement () {
			let formData = new FormData();
		
			for ( let key in this.editAdvertisementModal.advertisement ) {
				if(this.editAdvertisementModal.advertisement[key]) {
					formData.append(`advertisement[${key}]`, this.editAdvertisementModal.advertisement[key]);
				}
			}
			this.$store.dispatch('advertisement/updateAdvertisement', {id: this.editAdvertisementModal.advertisement.id, advertisement: formData}).then(() => {
				this.editAdvertisementModal = {
					active: false,
					advertisement: null
				}
				this.$notify({
					title: 'Advertentie bewerkt',
					message: 'Advertentie is succesvol bewerkt!',
					type: 'success',
					position: 'bottom-right'
				})
			})
		},
		handleImageSuccess(res, file) {
			this.newAdvertisement.imageUrl = URL.createObjectURL(file.raw);
		},
		beforeImageUpdate (file) {
			this.editAdvertisementModal.advertisement.image = file;
			this.blobToBase64(file).then((url) => {
				this.editAdvertisementModal.imagePreview = url;
			})
		},
		beforeImageUpload (file) {
			this.newAdvertisement.image = file;
			this.blobToBase64(file).then((url) => {
				this.imagePreview = url;
			})
		}
	}
}